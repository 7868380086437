.not-found {
  flex: 1;
  text-align: center;
}

body {
  text-align: center;
  padding: 150px;
}

h1 {
  font-size: 50px;
}

body {
  font: 20px Helvetica, sans-serif;
  color: #333;
}

article {
  display: block;
  text-align: left;
  width: 650px;
  margin: 0 auto;
}

a {
  color: #dc8100;
  text-decoration: none;
}

a:hover {
  color: #333;
  text-decoration: none;
}